exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-location-service-app-tsx": () => import("./../../../src/pages/location-service-app.tsx" /* webpackChunkName: "component---src-pages-location-service-app-tsx" */),
  "component---src-pages-location-service-tsx": () => import("./../../../src/pages/location-service.tsx" /* webpackChunkName: "component---src-pages-location-service-tsx" */),
  "component---src-pages-privacy-app-tsx": () => import("./../../../src/pages/privacy-app.tsx" /* webpackChunkName: "component---src-pages-privacy-app-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-request-tsx": () => import("./../../../src/pages/request.tsx" /* webpackChunkName: "component---src-pages-request-tsx" */),
  "component---src-pages-service-app-tsx": () => import("./../../../src/pages/service-app.tsx" /* webpackChunkName: "component---src-pages-service-app-tsx" */),
  "component---src-pages-service-tsx": () => import("./../../../src/pages/service.tsx" /* webpackChunkName: "component---src-pages-service-tsx" */)
}

