import './src/styles/global.css';
import 'swiper/css';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SwiperCore, { Autoplay, EffectCreative } from 'swiper';

SwiperCore.use([Autoplay, EffectCreative]);

gsap.registerPlugin(ScrollTrigger);
gsap.config({
  force3D: true,
});

ScrollTrigger.config({
  ignoreMobileResize: true,
  autoRefreshEvents: "visibilitychange,DOMContentLoaded,load",
});
